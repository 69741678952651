/* eslint-disable react/prop-types */
// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";

// Data
import React, { useEffect, useState } from "react";

import moment from "moment";
import { _fetchApi } from "redux/action/api";
import { ButtonGroup, Button, Modal, Table, Card } from "reactstrap";
import { formatNumber } from "redux/action/api";
const _bottom = { borderBottom: "1px solid #ccc" };

const Section = ({ description, amount, percentage }) => {
  const condition =
    description.includes("TOTAL RECURRENT REVENUE") ||
    description.includes("TOTAL BUDGET SIZE") ||
    description.includes("RECURRENT REVENUE") ||
    description.includes("TOTAL CAPITAL RECEIPT") ||
    description.includes("TOTAL RECURRENT EXPENDITURE");
  const styles = {
    fontWeight: condition ? "bold" : "",
    borderBottomWidth: 3,
    borderBottomStyle: "normal",
  };
  return (
    <div className="row">
      <div className="col-md-6" style={{ ...styles, ..._bottom }}>
        {description}
      </div>
      <div
        className="col-md-4"
        style={{ textAlign: "end", ...styles, ..._bottom }}
      >
        {formatNumber(amount)}
      </div>
      <div
        className="col-md-2"
        style={{ textAlign: "end", ...styles, ..._bottom }}
      >
        {percentage}
        {percentage ? "%" : ""}
      </div>
    </div>
  );
};

const Section2 = ({ amount_2, amount, label = "INCREASE", percentage }) => {
  const styles = { fontWeight: "bold" };
  const thisYr = moment().format("YYYY");
  const nextYr = parseInt(thisYr) + 1;
  return (
    <div className="row" style={{ marginBottom: 20 }}>
      <div className="col-md-3 m-0 p-0">
        <div style={{ ...styles, ..._bottom }}>{nextYr}</div>
        <div style={_bottom}>{formatNumber(amount)}</div>
      </div>
      <div className="col-md-3 m-0 p-0">
        <div style={{ ...styles, ..._bottom }}>{thisYr}</div>
        <div style={_bottom}>{formatNumber(amount_2)}</div>
      </div>
      <div className="col-md-4 m-0 p-0">
        <div style={{ textAlign: "end", ...styles, ..._bottom }}>{label}</div>
        <div style={{ textAlign: "end", ...styles, ..._bottom }}>
          {formatNumber(amount - amount_2)}
        </div>
      </div>

      <div className="col-md-2 m-0 p-0">
        <div></div>
        <div style={{ textAlign: "end", ...styles, ..._bottom }}>
          {percentage}
          {percentage ? "%" : "."}
        </div>
      </div>
    </div>
  );
};

export default function BudgetPerformance() {
  const thisYr = moment().format("YYYY");
  const nextYr = parseInt(thisYr) + 1;

  const [data, setData] = useState([]);
  const [recCap, setRecCap] = useState([]);
  const [sectorial, setSectorial] = useState([]);
  const [budget, setBBudget] = useState([]);
  const [compare, setCompare] = useState([]);
  const [recRev, setRecRev] = useState([]);
  const [recRevCompare, setRecRevCompare] = useState([]);
  const [capRec, setcapRec] = useState([]);
  const [recExp, setRecExp] = useState([]);
  const [capCompare, setCapCompare] = useState([]);
  const [secAllocation, setSecAllocation] = useState([]);
  const [mdaRank, setMdaRank] = useState([]);
  const [capital, setCapital] = useState([]);
  const [loading, setLoading] = useState(false);

  const getData = (b_type, cb) => {
    setLoading(true);
    _fetchApi(
      `/budget_performance?b_type=${b_type}`,
      (data) => {
        cb(data?.results);
        setLoading(false);
      },
      (err) => {
        console.log(err);
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    getData("TOTAL RECURRENT REVENUE PERFORMANCE", setData);
    getData("RECURRENT AND CAPITAL EXPENDITURE", setRecCap);
    getData("SECTORIAL PERFORMANCE", setSectorial);
    getData("BUDGET PROPOSAL", setBBudget);
    getData("BUDGET COMPARISON", setCompare);
    getData("RECURRENT REVENUE", setRecRev);
    getData("RECURRENT REVENUE COMPARISON", setRecRevCompare);
    getData("CAPITAL RECEIPT", setcapRec);
    getData("RECURRENT EXPENDITURE", setRecExp);
    getData("CAPITAL EXPENDITURE COMPARISON", setCapCompare);
    getData("SECTORAL ALLOCATIONS", setSecAllocation);
    getData("MDAs RANKING", setMdaRank);
    getData("CAPITAL EXPENDITURE", setCapital);
  }, []);
  //   RECURRENT AND CAPITAL EXPENDITURE
  const _style = { fontWeight: "bold", marginTop: 20 };
  return (
    <DashboardLayout>
      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card body>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="success"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h5" color="white">
                  Budget Performance
                </MDTypography>
              </MDBox>
              <h5 className="text-center p-3" style={_style}>
                {thisYr} BUDGET PERFORMANCE
              </h5>
              {/* {JSON.stringify({nextYr})} */}
              <div className="m-5">
                {data.map((item) => (
                  <div key={item.id}>
                    <Section
                      description={item.description}
                      amount={item.amount}
                      percentage={item.percentage}
                    />
                  </div>
                ))}
                <h5 style={_style}>
                  {thisYr} RECURRENT AND CAPITAL EXPENDITURE
                </h5>
                {recCap.map((item) => (
                  <div key={item.id}>
                    <Section
                      description={item.description}
                      amount={item.amount}
                      percentage={item.percentage}
                    />
                  </div>
                ))}

                <h5 style={_style}>SECTORIAL PERFORMANCE {thisYr}</h5>
                {sectorial.map((item) => (
                  <div key={item.id}>
                    <Section
                      description={item.description}
                      amount={item.amount}
                      percentage={item.percentage}
                    />
                  </div>
                ))}
                <h5 style={_style} className="text-center">
                  HIGHLIGHT OF THE {nextYr} BUDGET PROPOSAL
                </h5>
                {budget.map((item) => (
                  <div key={item.id}>
                    <Section
                      description={item.description}
                      amount={item.amount}
                      percentage={item.percentage}
                    />
                  </div>
                ))}
                <h5 style={_style}>
                  COMPARISON BETWEEN THE {nextYr} AND {thisYr} BUDGET
                </h5>
                {compare.map((item) => (
                  <div key={item.id}>
                    <Section2
                      amount_2={item.amount_2}
                      amount={item.amount}
                      percentage={item.percentage}
                      label="DIFFERENCE"
                    />
                  </div>
                ))}

                {recRev.map((item) => (
                  <div key={item.id}>
                    <Section
                      description={item.description}
                      amount={item.amount}
                      percentage={item.percentage}
                    />
                  </div>
                ))}
                <h5 style={_style}>
                  COMPARISON BETWEEN THE {nextYr} AND {thisYr} RECURRENT REVENUE
                </h5>
                {recRevCompare.map((item) => (
                  <div key={item.id}>
                    <Section2
                      amount_2={item.amount_2}
                      amount={item.amount}
                      percentage={item.percentage}
                    />
                  </div>
                ))}

                {capRec.map((item) => (
                  <div key={item.id}>
                    <Section
                      description={item.description}
                      amount={item.amount}
                      percentage={item.percentage}
                    />
                  </div>
                ))}
                <div style={{ marginTop: 20 }}>
                  {recExp.map((item) => (
                    <div key={item.id}>
                      <Section
                        description={item.description}
                        amount={item.amount}
                        percentage={item.percentage}
                      />
                    </div>
                  ))}
                </div>

                <div style={_style}>
                  {capital.map((item) => (
                    <div key={item.id}>
                      <Section
                        description={item.description}
                        amount={item.amount}
                        percentage={item.percentage}
                      />
                    </div>
                  ))}
                </div>

                <h5 style={_style}>
                  COMPARISON BETWEEN THE {nextYr} AND {thisYr} CAPITAL
                  EXPENDITURE
                </h5>
                {capCompare.map((item) => (
                  <div key={item.id}>
                    <Section2
                      amount_2={item.amount_2}
                      amount={item.amount}
                      percentage={item.percentage}
                    />
                  </div>
                ))}
                <h5 style={_style}>
                  TOTAL SECTORAL ALLOCATIONS IN FIGURES AND PERCENTAGES
                </h5>
                {secAllocation.map((item) => (
                  <div key={item.id}>
                    <Section
                      description={item.description}
                      amount={item.amount}
                      percentage={item.percentage}
                    />
                  </div>
                ))}
                <h5 style={_style}>MDAs RANKING ALLOCATIONS</h5>
                {mdaRank.map((item) => (
                  <div key={item.id}>
                    <Section
                      description={item.description}
                      amount={item.amount}
                      percentage={item.percentage}
                    />
                  </div>
                ))}
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
