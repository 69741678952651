import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  Image,
} from "@react-pdf/renderer";
import PropTypes from "prop-types";
import DM_SANS_NORMAL from "../../../assets/DM_Sans/DM_Sans/static/DMSans_24pt-SemiBold.ttf";
import DM_SANS_BOLD from "../../../assets/DM_Sans/DM_Sans/static/DMSans-ExtraBold.ttf";
import DM_SANS_ITALIC from "../../../assets/DM_Sans/DM_Sans/static/DMSans-Italic.ttf";
import TAHOMA_NORMAL from "../../../assets/Tahoma/static/TAHOMA_0.TTF";
import TAHOMA_BOLD from "../../../assets/Tahoma/static/TAHOMAB0.TTF";
import coat from "./coat.jpeg";
import { formatNumber } from "redux/action/api";
import { numberToWords } from "redux/action/api";
import { year } from "helper";

Font.register({
  family: "DM_SANS",
  fonts: [
    { src: DM_SANS_NORMAL },
    { src: DM_SANS_BOLD },
    { src: DM_SANS_ITALIC, fontStyle: "italic" },
  ],
});

Font.register({
  family: "Tahoma",
  fonts: [{ src: TAHOMA_NORMAL }, { src: TAHOMA_BOLD }],
});

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    padding: 40,
  },
  section: {
    margin: 5,
    padding: 10,
  },
  header: {
    fontSize: 16,
    textAlign: "center",
    marginBottom: 20,
  },
  text: {
    fontSize: 12,
    textAlign: "left",
    marginBottom: 5,
    fontFamily: "DM_SANS",
  },
  text1: {
    fontSize: 17,
    textAlign: "left",
    marginBottom: 10,
    fontFamily: "DM_SANS",
  },
  boldText: {
    fontSize: 12,
    textAlign: "left",
    fontWeight: "bold",
    marginBottom: 10,
  },
  signedSection: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 30,
  },
  signature: {
    fontSize: 12,
    textAlign: "center",
    marginTop: 40,
  },
  redSeal: {
    width: 100,
    height: 100,
    borderRadius: "50%",
    backgroundColor: "red",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: 10,
  },
  title: {
    fontSize: 16,
    textAlign: "center",
    textTransform: "uppercase",
    marginBottom: 20,
  },
  section: {
    marginBottom: 20,
  },
  heading: {
    fontSize: 12,
    marginBottom: 10,
  },
  content: {
    fontSize: 12,
    lineHeight: 1.5,
    fontWeight: "bold",
    fontFamily: "DM_SANS",
  },
  redText: {
    color: "red",
    borderStyle: "dotted",
    borderWidth: 3,
    borderBottomWidth: 2,
    textDecoration: "underline",
  },
  redText1: {
    color: "red",
    borderStyle: "dotted",
    borderWidth: 100,
    borderBottomWidth: 2,
    borderRightWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    // width: "15%",
    textAlign: "center",
    // fontWeight: "italic",
    fontFamily: "DM_SANS",
    fontSize: 12,
    // textDecoration: "underline",
  },
  image1: {
    width: 100,
    height: 70,
    // marginLeft: 190,
    alignItems: "center",
    justifyContent: "center",
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    flexDirection: "row",
  },
  tableColHeader: {
    width: "16.66%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    backgroundColor: "#f1f1f1",
    padding: 5,
  },
  tableColHeader2: {
    width: "100%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    // backgroundColor: "#f1f1f1",
    padding: 5,
  },
  tableCol: {
    width: "16.66%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 5,
  },
  tableCellHeader: {
    fontSize: 10,
    fontWeight: "bold",
    fontFamily: "DM_SANS",
    color: "#000",
  },
  tableCell: {
    fontSize: 10,
  },
  tableCell2: {
    fontSize: 10,
    fontWeight: "bold",
    fontFamily: "DM_SANS",
    // alignItems: "center",
    // justifyContent: "center",
    // textAlign: "center",
  },
});

const tableData = [
  [
    "Code",
    "ADMINISTRATIVE UNIT",
    "Personnel",
    "Overhead",
    "Capital",
    "Total Expenditure",
  ],
];

export const ExpenditurePdf = ({
  data = [],
  year = 0,
  newFilterHead = (f) => f,
  newFilter = (f) => f,
}) => {
  const calculatePersonnel = (node) => {
    if (!node.children || node.children.length === 0) {
      return node.personnel;
    } else {
      let sum = node.personnel || 0;
      for (const child of node.children) {
        sum += calculatePersonnel(child);
      }
      return sum;
    }
  };

  const calculateOverhead = (node) => {
    if (!node.children || node.children.length === 0) {
      return node.overhead;
    } else {
      let sum = node.overhead || 0;
      for (const child of node.children) {
        sum += calculateOverhead(child);
      }
      return sum;
    }
  };

  const calculateCapital = (node) => {
    if (!node.children || node.children.length === 0) {
      return node.capital;
    } else {
      let sum = node.capital || 0;
      for (const child of node.children) {
        sum += calculateCapital(child);
      }
      return sum;
    }
  };

  const calculateRec = (node) => {
    if (!node.children || node.children.length === 0) {
      return node.total_rec;
    } else {
      let sum = node.total_rec || 0;
      for (const child of node.children) {
        sum += calculateRec(child);
      }
      return sum;
    }
  };

  const calculateTotal = (node) => {
    if (!node.children || node.children.length === 0) {
      return node.total_exp;
    } else {
      let sum = node.total_exp || 0;
      for (const child of node.children) {
        sum += calculateTotal(child);
      }
      return sum;
    }
  };
  const calculateSum = (node, field) => {
    if (!node.children || node.children.length === 0) {
      return node[field] || 0;
    } else {
      let sum = node[field] || 0;
      for (const child of node.children) {
        sum += calculateSum(child, field);
      }
      return sum;
    }
  };

  const calculateGrandTotal = (data) => {
    let total = 0;
    data.forEach((item) => {
      total += calculateTotal(item);
    });
    return total;
  };

  const grandTotal = calculateGrandTotal(data);
  const formatTotal = (number) => {
    return number ? number.toFixed(2) : "0.00";
  };
  const renderItems = (item, index) => {
    const isTitle = [
      "0000000000000000000",
      "1000000000000000000",
      "01000000000000",
      "3000000000000000000",
      "7000000000000000000",
      "01000",
    ].includes(item.code);
    const actualPrev1 = parseFloat(calculateSum(item, "actual_amount"));
    const approveAmt = parseFloat(calculateSum(item, "approve_amount"));
    const percentageDiff = (actualPrev1 / approveAmt) * 100;

    return (
      <React.Fragment key={`group_${index}`}>
        {newFilterHead(item) ? null : (
          <View style={styles.tableRow}>
            <View style={[styles.tableCol, { width: "20%" }]}>
              <Text style={styles.tableCell2}>{isTitle ? "" : item.code}</Text>
            </View>
            <View style={[styles.tableCol, { width: "40%" }]}>
              <Text style={styles.tableCell2}>
                {isTitle ? "Total Expenditure" : item.description}
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={[styles.tableCell2, { textAlign: "right" }]}>
                {isNaN(calculatePersonnel(item))
                  ? "---"
                  : formatNumber(calculatePersonnel(item))}
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={[styles.tableCell2, { textAlign: "right" }]}>
                {isNaN(calculateOverhead(item))
                  ? "---"
                  : formatNumber(calculateOverhead(item))}
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={[styles.tableCell2, { textAlign: "right" }]}>
                {isNaN(calculateCapital(item))
                  ? "---"
                  : formatNumber(calculateCapital(item))}
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={[styles.tableCell2, { textAlign: "right" }]}>
                {isNaN(calculateTotal(item))
                  ? "---"
                  : formatNumber(calculateTotal(item))}
              </Text>
            </View>
          </View>
        )}

        {item.children &&
          item.children.map((child, idx) =>
            child.children && child.children.length ? (
              renderItems(child, idx)
            ) : newFilter(child) ? null : (
              <View style={styles.tableRow} key={idx}>
                <View style={[styles.tableCol, { width: "20%" }]}>
                  <Text style={styles.tableCell}>{child.code}</Text>
                </View>
                <View style={[styles.tableCol, { width: "40%" }]}>
                  <Text style={styles.tableCell}>{child.description}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={[styles.tableCell, { textAlign: "right" }]}>
                    {isNaN(child.personnel)
                      ? "---"
                      : formatNumber(child.personnel)}
                  </Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={[styles.tableCell, { textAlign: "right" }]}>
                    {isNaN(child.overhead)
                      ? "---"
                      : formatNumber(child.overhead)}
                  </Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={[styles.tableCell, { textAlign: "right" }]}>
                    {isNaN(child.capital) ? "---" : formatNumber(child.capital)}
                  </Text>
                </View>
                {/* <View style={styles.tableCol}>
                  <Text style={[styles.tableCell, { textAlign: "right" }]}>
                    {isNaN(
                      parseFloat(
                        (child.actual_amount / child.approve_amount) * 100
                      )
                    )
                      ? "0"
                      : formatNumber(
                          (child.actual_amount / child.approve_amount) * 100
                        )}{" "}
                    %
                  </Text>
                </View> */}
                <View style={styles.tableCol}>
                  <Text style={[styles.tableCell, { textAlign: "right" }]}>
                    {isNaN(child.total_exp)
                      ? "---"
                      : formatNumber(child.total_exp)}
                  </Text>
                </View>
              </View>
            )
          )}
      </React.Fragment>
    );
  };

  return (
    <Document>
      <Page size="A4" style={styles.page} orientation="landscape">
        <View
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: 30,
          }}
        >
          <Image src={require("./coat.jpeg")} style={styles.image1} />
        </View>
        <View style={styles.title}>
          <Text style={{ fontWeight: "bold", fontFamily: "DM_SANS" }}>
            Kastina State Appropriation Law, {year} (1446 A.H.)
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.content}>
            Kastina State Appropriation Law, {year} (1446 AH) to the tune of{" "}
            {numberToWords(formatTotal(parseFloat(grandTotal)))} (
            {formatNumber(formatTotal(parseFloat(grandTotal)))}) only and for
            other matters connected therewith.
          </Text>
          <Text style={styles.content}>
            The Kastina State House of Assembly Enacted as follows:
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.heading}>Citation and Commencement</Text>
          <Text style={styles.content}>
            1. This Law may be cited as the Kastina State Appropriation Law,{" "}
            {year}
            (1446 AH.) and shall come into operation on{" "}
            <Text style={styles.redText}>1st January</Text> {year} (1446 A.H.)
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.heading}>
            Issues and appropriation of N
            {formatNumber(formatTotal(parseFloat(grandTotal)))}
          </Text>
          <Text style={styles.content}>
            2(1) The Accountant General of Kastina State may when authorized to
            do so by warrant signed as provided in section 120(2) of the
            Constitution of the Federal Republic of Nigeria 1999 (as amended)
            issue from the Consolidated Revenue Fund of the State during the
            year ending 31st December, {year} the sum specified by the warrants
            not exceeding in the aggregate of N
            {formatNumber(formatTotal(parseFloat(grandTotal)))}.
          </Text>
        </View>
      </Page>
      <Page size="A4" style={styles.page} orientation="landscape">
        <View style={styles.section}>
          <Text style={styles.content}>
            {numberToWords(formatTotal(parseFloat(grandTotal)))} (
            {formatNumber(formatTotal(parseFloat(grandTotal)))}) Only being the
            total amount set forth opposite codes 010000000000 - 055100100100 in
            the schedule to this Law.
          </Text>
          <Text style={styles.content}>
            (2) The said sum shall be appropriated for the purpose and in the
            manner expressed in the Schedule.
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.heading}>Lapsing of unissued amount</Text>
          <Text style={styles.content}>
            3. The monies granted by this Law are provided for the services in
            respect of which monies will become payable within the year ending
            31st December, {year} and any lapse shall not be available for
            making any payment in any subsequent month.
          </Text>
        </View>
      </Page>
      <Page size="A4" style={styles.page} orientation="landscape">
        <View style={styles.table}>
          <View style={styles.tableColHeader2}>
            <Text style={styles.tableCell2}>Schedule Section 2</Text>
          </View>
          <View style={styles.tableColHeader2}>
            <Text style={styles.tableCell2}>{year} APPROVED BUDGET</Text>
          </View>
          <View style={styles.tableColHeader2}>
            <Text style={styles.tableCell2}>GRAND SUMMARY</Text>
          </View>
          {tableData.map((row, rowIndex) => (
            <View style={styles.tableRow} key={rowIndex}>
              {row.map((col, colIndex) => (
                <View
                  style={[
                    styles.tableColHeader,
                    colIndex === 0
                      ? { width: "20%" }
                      : colIndex === 1
                      ? { width: "40%" }
                      : null,
                  ]}
                  key={colIndex}
                >
                  <Text style={styles.tableCellHeader}>
                    {col}
                    {/* {colIndex} */}
                  </Text>
                </View>
              ))}
            </View>
          ))}
          {data.length > 0 &&
            data.map((item, index) => renderItems(item, index))}
        </View>
      </Page>

      <Page size="A4" style={styles.page} orientation="landscape">
        <View style={styles.header}>
          <Text style={{ fontWeight: "bold", fontFamily: "DM_SANS" }}>
            AUTHENTICATION BY THE CLERK
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.text}>
            THIS PRINTED IMPRESSION HAS BEEN CAREFULLY COMPARED BY ME WITH KATSINA
            STATE APPROPRIATION LAW, {year} (1446 A.H) WHICH WAS PASSED BY THE
            STATE HOUSE OF ASSEMBLY AND FOUND BY ME TO BE A TRUE AND CORRECTLY
            PRINTED COPY OF THE SAID LAW.
          </Text>
        </View>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            // alignContent: "center",
            // alignItems: "center",
          }}
        >
          <View style={{ marginLeft: "150", marginTop: "50" }}>
            <View
              style={{
                textDecoration: "underline",
                width: "100%",
                // height: "40",
                borderStyle: "dotted",
                borderWidth: 100,
                borderBottomWidth: 2,
                borderRightWidth: 0,
                borderLeftWidth: 0,
                borderTopWidth: 0,
                marginTop: 30,
              }}
            ></View>

            <Text style={[styles.text, { marginLeft: 50 }]}>
              Rt Hon. Nasir Yahaya
            </Text>
            <Text style={styles.text}>CLERK/HEAD OF LEGISLATIVE SERVICE</Text>
            <Text style={styles.text}>Kastina State HOUSE OF ASSEMBLY</Text>
          </View>
          {/* <View> */}
          <Image
            src={require("./download.jpeg")}
            style={{
              textDecoration: "underline",
              width: "30%",
              height: "230",
            }}
          />
          {/* </View> */}
        </View>
        {/* <View style={styles.signedSection}> */}
        {/* <View>
          <Text style={styles.text}>ASSENTED</Text>
          <Text style={styles.text}>28th DAY OF DECEMBER 2023</Text>
          <Text style={styles.text}>
            15th DAY OF JUMMADA AL-THANI 1445 (A.H)
          </Text>
        </View> */}

        <View
          style={{
            display: "flex",
            flexDirection: "row",
            // justifyContent: "space-between",
          }}
        >
          <Text style={[styles.text, { width: "10%", marginLeft: 40 }]}>
            ASSENTED
          </Text>
          <Text style={[styles.redText1, { width: "30%", fontSize: 10 }]}>
            28th
          </Text>
          <Text style={[styles.text, { width: "10%" }]}>DAY OF</Text>
          <Text style={[styles.redText1, { width: "30%", fontSize: 10 }]}>
            DECEMBER{" "}
          </Text>
          <Text style={[styles.text, { width: "10%" }]}>{year}</Text>
        </View>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: 20,
            // justifyContent: "space-between",
          }}
        >
          <Text
            style={[
              styles.redText1,
              {
                width: "40%",
                marginLeft: 40,
                fontSize: 10,
                // fontWeight: "italic",
              },
            ]}
          >
            15TH
          </Text>
          <Text style={[styles.text, { width: "10%" }]}>DAY OF</Text>
          {/* <Text style={styles.text}>DAY OF</Text> */}
          <Text style={[styles.redText1, { width: "30%", fontSize: 10 }]}>
            JUMMADA{" "}
          </Text>
          <Text style={[styles.text, { width: "10%" }]}>{year}</Text>
        </View>
        <View
          style={{
            alignContent: "center",
            justifyContent: "center",
            marginTop: 20,
          }}
        >
          <Text
            style={[
              styles.redText1,
              {
                width: "70%",
                textAlign: "center",
                marginLeft: "100",
                marginTop: "20",
              },
            ]}
          >
            {/* DECEMBER{" "} */}
          </Text>
          <Text style={[styles.text1, { textAlign: "center" }]}>
            Dikko Umar Radda
          </Text>
          <Text style={[styles.text, { textAlign: "center" }]}>
            EXECUTIVE GOVERNOR
          </Text>
          <Text style={[styles.text, , { textAlign: "center" }]}>
            Kastina State OF NIGERIA
          </Text>
        </View>
        {/* </View> */}
      </Page>
    </Document>
  );
};

ExpenditurePdf.propTypes = {
  year: PropTypes.number,
  newFilterHead: PropTypes.func,
  newFilter: PropTypes.func,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      approve_amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      actual_amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      prev_year2_actual_amount: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      prev_year1_approve_amount: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      budget_amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      children: PropTypes.arrayOf(
        PropTypes.shape({
          code: PropTypes.string.isRequired,
          description: PropTypes.string.isRequired,
          approve_amount: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
          ]).isRequired,
          actual_amount: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
          ]),
        })
      ),
    })
  ).isRequired,
};

export default ExpenditurePdf;
