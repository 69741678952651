/* eslint-disable react/prop-types */
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";

// Data
import React, { useEffect, useState, useCallback } from "react";
import Scrollbar from "components/Tree/Scrollbar";
import MDInput from "components/MDInput";
import { useNavigate } from "react-router-dom";

import moment from "moment";
import SearchBar from "components/SearchBar";
import { CSVLink } from "react-csv";
import { FaFileDownload } from "react-icons/fa";
import { _fetchApi } from "redux/action/api";
import { useYear } from "useYearList";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Table,
} from "reactstrap";
import { capitalizeWords } from "redux/action/api";
import { Spinner } from "reactstrap";
import { formatNumber } from "redux/action/api";
import { formatNumbers } from "redux/action/api";
import { postApi } from "redux/action/api_tbeams";
import { getNextYear } from "../getBudgetYear";
import { getAllData } from "layouts/DashboardNew";

export default function ReportBudgetShareCom({
  type = "budget_overview",
  route = "/get-report",
  options = [],
}) {
  const [results, setResults] = useState();
  const [form, setForm] = useState({
    budget_year: moment().format("YYYY"),
    query_type: type,
  });

  useEffect(() => {
    getNextYear((data) => setForm((p) => ({ ...p, budget_year: data })));
  }, []);

  const [loading, setLoading] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const years = useYear();

  const handleChange = ({ target: { name, value } }) => {
    setForm((p) => ({ ...p, [name]: value }));
  };

  const [selectedOption, setSelectedOption] = useState({
    value: "budget_overview",
    title: "Budget Overview",
  });
  const handleChangeSelect = (event) => {
    const selectedIndex = event.target.value;
    setSelectedOption(options[selectedIndex]);
  };
  const [openingBal, seTOpeningBal] = useState({});
  const [otherRecurrent, setOtherRecurrent] = useState([]);

  useEffect(() => {
    getAllData(
      "other_recurrent_new",
      "",
      `${form.budget_year}-01-01`,
      setOtherRecurrent
    );
  }, [form.budget_year]);

  const getOpeningBalance = useCallback(() => {
    postApi(
      `opening_balance?query_type=select&budget_year=${form.budget_year}`,
      {
        budget_year: form.budget_year,
      }
    )
      .then((data) => {
        let _data = data?.results && data?.results[0];
        seTOpeningBal(_data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {});
  }, [form.budget_year]);

  useEffect(() => {
    getOpeningBalance();
  }, [getOpeningBalance]);

  useEffect(() => {
    setLoading(true);
    _fetchApi(
      `${route}?query_type=${selectedOption.value}&budget_year=${form.budget_year}`,
      (data) => {
        setResults(data?.results && data?.results[0]);
        setLoading(false);
        // console.log(data?.results, "RAW DATA");
      },
      (err) => {
        console.log(err);
        setLoading(false);
      }
    );
  }, [form.budget_year, selectedOption.value]);

  const normalStyle = { textAlign: "right" };
  const boldStyle = { textAlign: "right", fontWeight: "bold" };
  const boldStyle1 = { fontWeight: "bold" };

  const emptyRow = () => {
    return (
      <tr style={{ fontSize: "13px" }}>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
    );
  };
  return (
    <DashboardLayout>
      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="success"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h5" color="white">
                  {capitalizeWords(selectedOption.value)}
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                {/* {JSON.stringify({ results })} */}
                <div className="d-flex justify-content-between m-3 flex-wrap">
                  <div className="col-md-2 col-6 mb-2">
                    <Input
                      type="select"
                      name="budget_year"
                      value={form.budget_year}
                      onChange={handleChange}
                    >
                      <option>--select--</option>
                      {years?.map((item) => (
                        <option key={item}>{item}</option>
                      ))}
                    </Input>
                  </div>
                  <div className="col-md-2 ">
                    {/* <select
                      className="form-control"
                      name="query_type"
                      value={
                        selectedOption
                          ? options.findIndex(
                              (opt) => opt.value === selectedOption.value
                            )
                          : ""
                      }
                      onChange={handleChangeSelect}
                    >
                      <option>Select report type</option>
                      {options.map((item, i) => (
                        <option value={i} key={i}>
                          {item.title}
                        </option>
                      ))}
                    </select> */}
                  </div>
                  <div className="col-6">
                    <SearchBar />
                  </div>
                  <div className="mx-auto">
                    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                      <DropdownToggle
                        className="btn btn-primary text-white mb-1 ml-3"
                        caret
                      >
                        <FaFileDownload
                          color="white"
                          size="1.2rem"
                          className="mr-5"
                        />
                        Download
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem
                          onClick={() => console.log("Option 1 clicked")}
                        >
                          Excel
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => console.log("Option 2 clicked")}
                        >
                          PDF
                        </DropdownItem>
                        <DropdownItem />
                        <DropdownItem
                          onClick={() => console.log("Another option clicked")}
                        >
                          Word
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                </div>
                <div className="p-3">
                  {loading ? (
                    <div className="loading-container text-center">
                      <Spinner color="primary" style={{ marginTop: "20px" }} />{" "}
                      Loading...
                    </div>
                  ) : (
                    <Scrollbar>
                      <Table
                        className="table table-striped table-bordered"
                        // ref={tableRef}
                      >
                        <thead
                          style={{
                            fontSize: "12px",
                            borderWidth: 1,
                            borderColor: "black",
                            textAlign: "center",
                          }}
                        >
                          <tr style={{ backgroundColor: "orange" }}>
                            <th style={{ backgroundColor: "orange" }}>
                              Description
                            </th>
                            <th
                              style={{
                                backgroundColor: "orange",
                                fontWeight: "bolder",
                              }}
                            >{`${
                              parseInt(form.budget_year) - 2
                            } Full year  Actual(₦)`}</th>
                            <th style={{ backgroundColor: "orange" }}>{`${
                              parseInt(form.budget_year) - 1
                            } Approved Budget(₦)`}</th>
                            <th style={{ backgroundColor: "orange" }}>{`${
                              parseInt(form.budget_year) - 1
                            } Performance January To August(₦)`}</th>
                            {/* <th>{`${parseInt(form.budget_year)} MDA Proposal`}</th> */}
                            <th
                              style={{ backgroundColor: "orange" }}
                            >{`${parseInt(
                              form.budget_year
                            )} Proposed Budget`}</th>
                            <th
                              style={{ backgroundColor: "orange" }}
                            >{`${parseInt(
                              form.budget_year
                            )} Approved Budget`}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr style={{ fontSize: "13px" }}>
                            <td style={boldStyle1}>Opening Balance</td>
                            <td style={boldStyle}>
                              {formatNumbers(openingBal?.prev_year2_amt)}
                            </td>
                            <td style={boldStyle}>
                              {formatNumbers(openingBal?.prev_year1_amt)}
                            </td>
                            <td style={boldStyle}>
                              {formatNumbers(openingBal?.prev_year1_closing)}
                            </td>
                            <td style={boldStyle}>
                              {formatNumbers(openingBal?.opening_balance)}
                            </td>
                            <td style={boldStyle}>
                              {formatNumbers(openingBal?.opening_balance)}
                            </td>
                          </tr>
                          <tr style={{ fontSize: "13px" }}>
                            <td style={boldStyle1}>Recurrent Revenue</td>
                            <td style={boldStyle}>
                              {formatNumbers(
                                results?.prev_year2_actual_amount_rec_revenue
                              )}
                            </td>
                            <td style={boldStyle}>
                              {formatNumbers(
                                results?.prev_year1_approve_amount_rec_revenue
                              )}
                            </td>
                            <td style={boldStyle}>
                              {formatNumbers(
                                results?.prev_year1_actual_amount_rec_revenue
                              )}
                            </td>
                            <td style={boldStyle}>
                              {formatNumbers(
                                results?.budget_amount_rec_revenue
                              )}
                            </td>
                            <td style={boldStyle}>
                              {formatNumbers(
                                results?.approve_amount_rec_revenue
                              )}
                            </td>
                          </tr>
                          <tr style={{ fontSize: "13px" }}>
                            <td>11 - GOVERNMENT SHARE OF FAAC</td>
                            <td style={normalStyle}>
                              {formatNumbers(
                                results?.prev_year2_actual_amount_fac
                              )}
                            </td>
                            <td style={normalStyle}>
                              {formatNumbers(
                                results?.prev_year1_approve_amount_fac
                              )}
                            </td>
                            <td style={normalStyle}>
                              {formatNumbers(
                                results?.prev_year1_actual_amount_fac
                              )}
                            </td>
                            <td style={normalStyle}>
                              {formatNumbers(results?.budget_amount_fac)}
                            </td>
                            <td style={normalStyle}>
                              {formatNumbers(results?.approve_amount_fac)}
                            </td>
                          </tr>
                          <tr style={{ fontSize: "13px" }}>
                            <td>12 - INDEPENDENT REVENUE</td>
                            <td style={normalStyle}>
                              {formatNumbers(
                                results?.prev_year2_actual_amount_igr
                              )}
                            </td>
                            <td style={normalStyle}>
                              {formatNumbers(
                                results?.prev_year1_approve_amount_igr
                              )}
                            </td>
                            <td style={normalStyle}>
                              {formatNumbers(
                                results?.prev_year1_actual_amount_igr
                              )}
                            </td>
                            <td style={normalStyle}>
                              {formatNumbers(results?.budget_amount_igr)}
                            </td>
                            <td style={normalStyle}>
                              {formatNumbers(results?.approve_amount_igr)}
                            </td>
                          </tr>
                          {emptyRow()}
                          <tr style={{ fontSize: "13px" }}>
                            <td style={boldStyle1}>Recurrent Capital</td>
                            <td style={boldStyle}>
                              {formatNumbers(
                                results?.prev_year2_actual_amount_rec_exp
                              )}
                            </td>
                            <td style={boldStyle}>
                              {formatNumbers(
                                results?.prev_year1_approve_amount_rec_exp
                              )}
                            </td>
                            <td style={boldStyle}>
                              {formatNumbers(
                                results?.prev_year1_actual_amount_rec_exp
                              )}
                            </td>
                            <td style={boldStyle}>
                              {formatNumbers(results?.budget_amount_rec_exp)}
                            </td>
                            <td style={boldStyle}>
                              {formatNumbers(results?.approve_amount_rec_exp)}
                            </td>
                          </tr>
                          <tr style={{ fontSize: "13px" }}>
                            <td> 21 - PERSONNEL COST</td>
                            <td style={normalStyle}>
                              {formatNumbers(
                                results?.prev_year2_actual_amount_pers
                              )}
                            </td>
                            <td style={normalStyle}>
                              {formatNumbers(
                                results?.prev_year1_approve_amount_pers
                              )}
                            </td>
                            <td style={normalStyle}>
                              {formatNumbers(
                                results?.prev_year1_actual_amount_pers
                              )}
                            </td>
                            <td style={normalStyle}>
                              {formatNumbers(results?.budget_amount_pers)}
                            </td>
                            <td style={normalStyle}>
                              {formatNumbers(results?.approve_amount_pers)}
                            </td>
                          </tr>
                          <tr style={{ fontSize: "13px" }}>
                            <td>22 - OTHER RECURRENT COST</td>
                            <td style={normalStyle}>
                              {formatNumbers(
                                results?.prev_year2_actual_amount_over
                              )}
                            </td>
                            <td style={normalStyle}>
                              {formatNumbers(
                                results?.prev_year1_approve_amount_over
                              )}
                            </td>
                            <td style={normalStyle}>
                              {formatNumbers(
                                results?.prev_year1_actual_amount_over
                              )}
                            </td>
                            <td style={normalStyle}>
                              {formatNumbers(results?.budget_amount_over)}
                            </td>
                            <td style={normalStyle}>
                              {formatNumbers(results?.approve_amount_over)}
                            </td>
                          </tr>
                          {emptyRow()}
                          <tr style={{ fontSize: "13px" }}>
                            <td style={boldStyle1}>
                              Transfer to Capital Account
                            </td>
                            <td style={normalStyle}>
                              {formatNumbers(
                                results?.prev_year2_actual_amount_rec_revenue +
                                  openingBal?.prev_year2_amt -
                                  results?.prev_year2_actual_amount_rec_exp
                              )}
                            </td>
                            <td style={normalStyle}>
                              {formatNumbers(
                                results?.prev_year1_approve_amount_rec_revenue +
                                  openingBal?.prev_year1_amt -
                                  results?.prev_year1_approve_amount_rec_exp
                              )}
                            </td>
                            <td style={normalStyle}>
                              {formatNumbers(
                                results?.prev_year1_actual_amount_rec_revenue +
                                  openingBal?.rev_year1_closing -
                                  results?.prev_year1_actual_amount_rec_exp
                              )}
                            </td>
                            <td style={normalStyle}>
                              {formatNumbers(
                                results?.budget_amount_rec_revenue +
                                  openingBal?.opening_balance -
                                  results?.budget_amount_rec_exp
                              )}
                            </td>
                            <td style={normalStyle}>
                              {formatNumbers(
                                parseFloat(
                                  results?.approve_amount_rec_revenue +
                                    openingBal?.opening_balance
                                ) - results?.approve_amount_rec_exp
                              )}
                            </td>
                          </tr>
                          {emptyRow()}
                          <tr style={{ fontSize: "13px" }}>
                            <td style={boldStyle1}>Capital Receipt</td>
                            <td style={boldStyle}>
                              {formatNumbers(
                                results?.prev_year2_actual_amount_cap_rec
                              )}
                            </td>
                            <td style={boldStyle}>
                              {formatNumbers(
                                results?.prev_year1_approve_amount_cap_rec
                              )}
                            </td>
                            <td style={boldStyle}>
                              {formatNumbers(
                                results?.prev_year1_actual_amount_cap_rec
                              )}
                            </td>
                            <td style={boldStyle}>
                              {formatNumbers(results?.budget_amount_cap_rec)}
                            </td>
                            <td style={boldStyle}>
                              {formatNumbers(results?.approve_amount_cap_rec)}
                            </td>
                          </tr>
                          <tr style={{ fontSize: "13px" }}>
                            <td>13 - AID AND GRANT</td>
                            <td style={normalStyle}>
                              {formatNumbers(
                                results?.prev_year2_actual_amount_aid
                              )}
                            </td>
                            <td style={normalStyle}>
                              {formatNumbers(
                                results?.prev_year1_approve_amount_aid
                              )}
                            </td>
                            <td style={normalStyle}>
                              {formatNumbers(
                                results?.prev_year1_actual_amount_aid
                              )}
                            </td>
                            <td style={normalStyle}>
                              {formatNumbers(results?.budget_amount_aid)}
                            </td>
                            <td style={normalStyle}>
                              {formatNumbers(results?.approve_amount_aid)}
                            </td>
                          </tr>

                          <tr style={{ fontSize: "13px" }}>
                            <td>
                              14 - CAPITAL DEVELOPMENT FUND (CDF) RECEIPTS
                            </td>
                            <td style={normalStyle}>
                              {formatNumbers(
                                results?.prev_year2_actual_amount_cdf
                              )}
                            </td>
                            <td style={normalStyle}>
                              {formatNumbers(
                                results?.prev_year1_approve_amount_cdf
                              )}
                            </td>
                            <td style={normalStyle}>
                              {formatNumbers(
                                results?.prev_year1_actual_amount_cdf
                              )}
                            </td>
                            <td style={normalStyle}>
                              {formatNumbers(results?.budget_amount_cdf)}
                            </td>
                            <td style={normalStyle}>
                              {formatNumbers(results?.approve_amount_cdf)}
                            </td>
                          </tr>
                          {emptyRow()}
                          <tr style={{ fontSize: "13px" }}>
                            <td style={boldStyle1}>32 - Capital Expenditure</td>
                            <td style={boldStyle}>
                              {formatNumbers(
                                results?.prev_year2_actual_amount_cap
                              )}
                            </td>
                            <td style={boldStyle}>
                              {formatNumbers(
                                results?.prev_year1_approve_amount_cap
                              )}
                            </td>
                            <td style={boldStyle}>
                              {formatNumbers(
                                results?.prev_year1_actual_amount_cap
                              )}
                            </td>
                            <td style={boldStyle}>
                              {formatNumbers(results?.budget_amount_cap)}
                            </td>
                            <td style={boldStyle}>
                              {formatNumbers(results?.approve_amount_cap)}
                            </td>
                          </tr>
                          {emptyRow()}
                          <tr style={{ fontSize: "13px" }}>
                            <td style={boldStyle1}>
                              Total Revenue (including OB)
                            </td>
                            <td style={boldStyle}>
                              {formatNumber(
                                openingBal?.prev_year2_amt +
                                  results?.prev_year2_actual_amount_rec_revenue +
                                  results?.prev_year2_actual_amount_cap_rec
                              )}
                            </td>
                            <td style={boldStyle}>
                              {formatNumber(
                                openingBal?.prev_year1_amt +
                                  results?.prev_year1_approve_amount_rec_revenue +
                                  results?.prev_year1_approve_amount_cap_rec
                              )}
                            </td>
                            <td style={boldStyle}>
                              {formatNumber(
                                openingBal?.prev_year1_closing +
                                  results?.prev_year1_actual_amount_rec_revenue +
                                  results?.prev_year1_actual_amount_cap_rec
                              )}
                            </td>
                            <td style={boldStyle}>
                              {formatNumber(
                                openingBal?.opening_balance +
                                  results?.budget_amount_rec_revenue +
                                  results?.budget_amount_cap_rec
                              )}
                            </td>
                            <td style={boldStyle}>
                              {formatNumber(
                                openingBal?.opening_balance +
                                  results?.approve_amount_rec_revenue +
                                  results?.approve_amount_cap_rec
                              )}
                            </td>
                          </tr>

                          <tr style={{ fontSize: "13px" }}>
                            <td style={boldStyle1}>Total Expenditure</td>
                            <td style={boldStyle}>
                              {formatNumbers(
                                results?.prev_year2_actual_amount_rec_exp +
                                  results?.prev_year2_actual_amount_cap
                              )}
                            </td>
                            <td style={boldStyle}>
                              {formatNumbers(
                                results?.prev_year1_approve_amount_rec_exp +
                                  results?.prev_year1_approve_amount_cap
                              )}
                            </td>
                            <td style={boldStyle}>
                              {formatNumbers(
                                results?.prev_year1_actual_amount_rec_exp +
                                  results?.prev_year1_actual_amount_cap
                              )}
                            </td>
                            <td style={boldStyle}>
                              {formatNumber(
                                results?.budget_amount_rec_exp +
                                  results?.budget_amount_cap
                              )}
                            </td>
                            <td style={boldStyle}>
                              {formatNumber(
                                results?.approve_amount_rec_exp +
                                  results?.approve_amount_cap
                              )}
                            </td>
                          </tr>
                          {emptyRow()}
                          <tr style={{ fontSize: "13px" }}>
                            <td style={boldStyle1}>Closing Balance</td>
                            <td style={boldStyle}>
                              {formatNumber(
                                openingBal?.prev_year2_amt +
                                  results?.prev_year2_actual_amount_rec_revenue +
                                  results?.prev_year2_actual_amount_cap_rec -
                                  (results?.prev_year2_actual_amount_rec_exp +
                                    results?.prev_year2_actual_amount_cap)
                              )}
                            </td>
                            <td style={boldStyle}>
                              {formatNumber(
                                openingBal?.prev_year1_amt +
                                  results?.prev_year1_approve_amount_rec_revenue +
                                  results?.prev_year1_approve_amount_cap_rec -
                                  (results?.prev_year1_approve_amount_rec_exp +
                                    results?.prev_year1_approve_amount_cap)
                              )}
                            </td>
                            <td style={boldStyle}>
                              {formatNumber(
                                openingBal?.prev_year1_closing +
                                  results?.prev_year1_actual_amount_rec_revenue +
                                  results?.prev_year1_actual_amount_cap_rec -
                                  (results?.prev_year1_actual_amount_rec_exp +
                                    results?.prev_year1_actual_amount_cap)
                              )}
                            </td>
                            <td style={boldStyle}>
                              {formatNumber(
                                openingBal?.opening_balance +
                                  results?.budget_amount_rec_revenue +
                                  results?.budget_amount_cap_rec -
                                  (results?.budget_amount_rec_exp +
                                    results?.budget_amount_cap)
                              )}
                            </td>
                            <td style={boldStyle}>
                              {formatNumber(
                                results?.approve_amount_rec_exp +
                                  results?.approve_amount_cap -
                                  (openingBal?.opening_balance +
                                    results?.approve_amount_rec_revenue +
                                    results?.approve_amount_cap_rec)
                              )}{" "}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </Scrollbar>
                  )}
                </div>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
