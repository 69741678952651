/* eslint-disable react/prop-types */
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";

// Data
import React, { useEffect, useRef, useState } from "react";
import Scrollbar from "components/Tree/Scrollbar";
import { CSVLink } from "react-csv";
import { FaFileDownload } from "react-icons/fa";
import { _fetchApi } from "redux/action/api";
import { useYear } from "useYearList";
import { capitalizeWords } from "redux/action/api";
import { unflattenTable2, unflatten } from "redux/action/api";
import htmlDocx from "html-docx-js/dist/html-docx";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import {
  ButtonGroup,
  Button,
  Input,
  Modal,
  Spinner,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Table,
} from "reactstrap";
import MDAReportModal from "./MDAReportsModal";
import { formatNumber } from "redux/action/api";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { PDFViewer } from "@react-pdf/renderer";
import { ExpenditurePdf } from "./ExpenditurePdf";
import { headerColors } from "redux/action/api";
import { getNextYear } from "../getBudgetYear";
import moment from "moment";

export const newFilter = (item) => {
  return (
    (item.prev_year2_actual_amount === 0 ||
      item.prev_year2_actual_amount === undefined ||
      item.prev_year2_actual_amount === null) &&
    (item.prev_year1_approve_amount === 0 ||
      item.prev_year1_approve_amount === undefined ||
      item.prev_year1_approve_amount === null) &&
    (item.prev_year1_actual_amount === 0 ||
      item.prev_year1_actual_amount === undefined ||
      item.prev_year1_actual_amount === null) &&
    (item.budget_amount === 0 ||
      item.budget_amount === undefined ||
      item.budget_amount === null) &&
    (item.approve_amount === 0 ||
      item.approve_amount === undefined ||
      item.approve_amount === null)
  );
};

export default function ReportExpByMDA({
  type = "total_ex_by_mda",
  route = "/get-report",
  options = [],
  title = "",
  chartType = "get_head_eco",
}) {
  const [selectMDA, setSelectMDA] = useState({});
  const [modal, setModal] = useState(false);
  // const tableRef = useRef();
  const toggle = () => setModal(!modal);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle1 = () => setDropdownOpen((prevState) => !prevState);

  const [treeData, setTreeData] = useState([]);
  const [form, setForm] = useState({
    budget_year: moment().format("YYYY"),
    query_type: type,
  });
  const [loading, setLoading] = useState(false);
  const years = useYear();
  const [selectedOption, setSelectedOption] = useState({
    value: type,
    title,
    code: "",
  });
  const handleChange = ({ target: { name, value } }) => {
    setForm((p) => ({ ...p, [name]: value }));
  };

  const handleButtonClick = (value, title, code) => {
    setSelectedOption({ value, title, code });
  };

  const [getHead, setGetHead] = useState([]);

  useEffect(() => {
    getNextYear((data) => setForm((p) => ({ ...p, budget_year: data })));
  }, []);

  function ensureNumber(value) {
    return isNaN(value) || value === null || value === undefined
      ? 0
      : Number(value);
  }

  const calculateprevyear = (node) => {
    if (!node.children || node.children.length === 0) {
      return ensureNumber(node.prev_year2_actual_amount);
    } else {
      let sum = ensureNumber(node.prev_year2_actual_amount) || 0;
      for (const child of node.children) {
        sum += calculateprevyear(child);
      }
      return sum;
    }
  };

  const calculateApprovePrev1 = (node) => {
    if (!node.children || node.children.length === 0) {
      return ensureNumber(node.prev_year1_approve_amount);
    } else {
      let sum = ensureNumber(node.prev_year1_approve_amount) || 0;
      for (const child of node.children) {
        sum += calculateApprovePrev1(child);
      }
      return sum;
    }
  };
  const calculateActualPrev1 = (node) => {
    if (!node.children || node.children.length === 0) {
      return ensureNumber(node.prev_year1_actual_amount);
    } else {
      let sum = ensureNumber(node.prev_year1_actual_amount) || 0;
      for (const child of node.children) {
        sum += calculateActualPrev1(child);
      }
      return sum;
    }
  };
  const calculateBudgetAmt = (node) => {
    if (!node.children || node.children.length === 0) {
      return ensureNumber(node.budget_amount);
    } else {
      let sum = ensureNumber(node.budget_amount) || 0;
      for (const child of node.children) {
        sum += calculateBudgetAmt(child);
      }
      return sum;
    }
  };

  const calculateApproveAmt = (node) => {
    if (!node.children || node.children.length === 0) {
      return ensureNumber(node.approve_amount);
    } else {
      let sum = ensureNumber(node.approve_amount) || 0;
      for (const child of node.children) {
        sum += calculateApproveAmt(child);
      }
      return sum;
    }
  };

  const newFilterHead = (item) => {
    return (
      (calculateprevyear(item) === 0 ||
        calculateprevyear(item) === undefined ||
        calculateprevyear(item) === null) &&
      (calculateApprovePrev1(item) === 0 ||
        calculateApprovePrev1(item) === undefined ||
        calculateApprovePrev1(item) === null) &&
      (calculateActualPrev1(item) === 0 ||
        calculateActualPrev1(item) === undefined ||
        calculateActualPrev1(item) === null) &&
      (calculateBudgetAmt(item) === 0 ||
        calculateBudgetAmt(item) === undefined ||
        calculateBudgetAmt(item) === null) &&
      (calculateApproveAmt(item) === 0 ||
        calculateApproveAmt(item) === undefined ||
        calculateApproveAmt(item) === null)
    );
  };

  useEffect(() => {
    setLoading(true);
    _fetchApi(
      `${route}?query_type=${chartType}&budget_year=${form.budget_year}&like=${selectedOption.code}`,
      (data) => {
        let _data = data?.results;
        setGetHead(_data);
        console.log(_data, "HEAD_NEW", chartType, form.budget_year);
        setLoading(false);
      },
      (err) => {
        console.log(err);
        setLoading(false);
      }
    );
  }, []);

  useEffect(() => {
    setLoading(true);
    _fetchApi(
      `${route}?query_type=${selectedOption.value}&budget_year=${form.budget_year}&like=${selectedOption.code}`,
      (data) => {
        let _data = data?.results;
        setTreeData(_data);
        console.log(
          _data,
          "REVENUE LEEEE",
          selectedOption.value,
          form.budget_year
        );

        setLoading(false);
        // console.log(JSON.stringify(data?.results), "DDDDDDDDDDDD");
      },
      (err) => {
        console.log(err);
        setLoading(false);
      }
    );
  }, [form.budget_year, selectedOption.value, selectedOption.code]);

  const tableRef = useRef();
  const dataByMDA = {};
  treeData?.forEach((item) => {
    if (Object.keys(dataByMDA)?.includes(item.mda_code)) {
      dataByMDA[item.mda_code] = [...dataByMDA[item.mda_code], item];
    } else {
      dataByMDA[item.mda_code] = [item];
    }
  });

  const _prev_year2_actual_amount = treeData?.reduce(
    (a, b) => a + ensureNumber(b.prev_year2_actual_amount),
    0
  );
  const _prev_year1_approve_amount = treeData?.reduce(
    (a, b) => a + ensureNumber(b.prev_year1_approve_amount),
    0
  );
  const _prev_year1_actual_amount = treeData?.reduce(
    (a, b) => a + ensureNumber(b.prev_year1_actual_amount),
    0
  );

  const _budget_amount = treeData?.reduce(
    (a, b) => a + ensureNumber(b.budget_amount),
    0
  );
  const _approve_amount = treeData?.reduce(
    (a, b) => a + ensureNumber(b.approve_amount),
    0
  );

  const [pdf, setPdf] = useState(false);

  const stylesBold = {
    textAlign: "right",
    fontWeight: "bold",
    backgroundColor: "#C0C0C0",
  };
  const otherStyle = {
    fontWeight: "bold",
    backgroundColor: "green",
    color: "white",
    textAlign: "right",
  };
  const bg = { backgroundColor: "orange" };
  const [pdfLoading, setPdfLoading] = useState(false);

  const handleExportToWord = () => {
    const tableHtml = tableRef.current.outerHTML;
    const converted = htmlDocx.asBlob(tableHtml);
    saveAs(converted, `${title}-report.docx`);
  };

  // const handleExportToPDF = () => {
  //   setPdfLoading(true);
  //   const input = document.getElementById("table2");
  //   console.log(input);
  //   if (input) {
  //     html2canvas(input)
  //       .then((canvas) => {
  //         const imgData = canvas.toDataURL("image/png");
  //         const pdf = new jsPDF("p", "mm", "a4");
  //         const imgProps = pdf.getImageProperties(imgData);
  //         const pdfWidth = pdf.internal.pageSize.getWidth();
  //         const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
  //         pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
  //         pdf.save(`${title.replace(/ /g, "_")}.pdf`);
  //         setPdfLoading(false);
  //       })
  //       .catch((error) => {
  //         console.error("Error generating PDF:", error);
  //         setPdfLoading(false);
  //       });
  //   } else {
  //     console.error("Element not found");
  //     setPdfLoading(false);
  //   }
  // };

  function mergeDataWithHead(data) {
    return getHead?.map((item2) => {
      const matchedItem = data?.find((item1) => item1.head === item2.head);
      // console.log( "MATCHED",item2.head);
      if (matchedItem) {
        return { ...item2, ...matchedItem };
      } else {
        return item2;
      }
    });
  }

  const generateTable2 = (data) => {
    const renderItems = (item, index) => {
      const isTitle =
        item.head === "0000000000000000000" ||
        item.head === "1000000000000000000" ||
        item.head === "2000000000000000000" ||
        item.head === "3000000000000000000" ||
        item.head === "5000000000000000000" ||
        item.head === "7000000000000000000" ||
        item.head === "8000000000000000000";
      return (
        <React.Fragment key={`group_${index}`}>
          {newFilterHead(item) ? null : (
            <tr
              style={{
                fontSize: "12px",
                fontWeight: "bold",
                color: "black",
              }}
              onClick={() => {
                setSelectMDA(item);
              }}
            >
              {/* {isTitle ? "" : item.head} */}
              <td
                style={{
                  backgroundColor: headerColors[item.parentHeads.length],
                  color: "black",
                }}
              >
                {isTitle ? "" : item.head}
              </td>
              <td
                style={{
                  backgroundColor: headerColors[item.parentHeads.length],
                  color: "black",
                }}
              >
                {isTitle ? selectedOption.title : item.description}

                {/* {form.type} */}
              </td>
              <td
                style={{
                  textAlign: "right",
                  backgroundColor: headerColors[item.parentHeads.length],
                  color: "black",
                }}
              >
                {formatNumber(calculateprevyear(item))}
              </td>
              <td
                style={{
                  textAlign: "right",
                  backgroundColor: headerColors[item.parentHeads.length],
                  color: "black",
                }}
              >
                {formatNumber(calculateApprovePrev1(item))}
              </td>
              <td
                style={{
                  textAlign: "right",
                  backgroundColor: headerColors[item.parentHeads.length],
                  color: "black",
                }}
              >
                {formatNumber(calculateActualPrev1(item))}
              </td>
              <td
                style={{
                  textAlign: "right",
                  backgroundColor: headerColors[item.parentHeads.length],
                  color: "black",
                }}
              >
                {formatNumber(calculateBudgetAmt(item))}
              </td>

              <td
                style={{
                  textAlign: "right",
                  backgroundColor: headerColors[item.parentHeads.length],
                  color: "black",
                }}
              >
                {formatNumber(calculateApproveAmt(item))}
              </td>
            </tr>
          )}

          {item.children?.map((child, idx) => {
            console.log(child.prev_year1_approve_amount, "CHILDDDD");
            if (child.children && child.children.length) {
              return renderItems(child, idx);
            } else if (newFilter(child)) {
              return null;
            } else {
              return (
                <tr key={child.head} style={{ fontSize: "11px" }}>
                  <td>{child.head}</td>
                  <td>{child.description}</td>
                  <td style={{ textAlign: "right" }}>
                    {formatNumber(child.prev_year2_actual_amount)}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {isNaN(child.prev_year1_approve_amount)
                      ? "---"
                      : formatNumber(child.prev_year1_approve_amount)}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {isNaN(child.prev_year1_actual_amount)
                      ? "---"
                      : formatNumber(child.prev_year1_actual_amount)}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {isNaN(child.budget_amount)
                      ? "---"
                      : formatNumber(child.budget_amount)}
                  </td>

                  <td style={{ textAlign: "right" }}>
                    {isNaN(child.approve_amount)
                      ? "---"
                      : formatNumber(child.approve_amount)}
                  </td>
                </tr>
              );
            }
          })}
        </React.Fragment>
      );
    };
    const _ddata = mergeDataWithHead(data);
    const tt = unflattenTable2(_ddata);
    // console.log(tt, "DKDD");
    return tt?.map((item, i) => renderItems(item, i));
  };

  return (
    <>
      <DashboardLayout>
        <MDBox pt={2} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <div>
                <Modal
                  isOpen={modal}
                  toggle={toggle}
                  size="lg"
                  style={{
                    marginLeft: "30%",
                  }}
                >
                  <MDAReportModal
                    toggle={toggle}
                    mda_name={selectMDA.description}
                  />
                </Modal>
              </div>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="success"
                  borderRadius="lg"
                  coloredShadow="info"
                  className="d-flex justify-content-between "
                >
                  <MDTypography variant="h5" color="white">
                    {capitalizeWords(selectedOption.title)}
                  </MDTypography>

                  {/* {JSON.stringify(treeData)} */}
                  <div>
                    {pdf ? (
                      <Button onClick={() => setPdf(!pdf)} color="danger">
                        Close
                      </Button>
                    ) : (
                      <Dropdown isOpen={dropdownOpen} toggle={toggle1}>
                        <DropdownToggle
                          className="btn btn-primary text-white ml-3"
                          caret
                        >
                          <FaFileDownload
                            color="white"
                            size="1.2rem"
                            className="mr-5"
                          />
                          Download
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem
                            onClick={() => console.log("Option 1 clicked")}
                          >
                            <DownloadTableExcel
                              filename={selectedOption.title}
                              sheet={selectedOption.title}
                              currentTableRef={tableRef.current}
                            >
                              Export to Excel
                            </DownloadTableExcel>
                          </DropdownItem>
                          <DropdownItem onClick={() => setPdf(!pdf)}>
                            PDF
                          </DropdownItem>
                          <DropdownItem />
                          <DropdownItem onClick={handleExportToWord}>
                            Export Word
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    )}
                  </div>
                </MDBox>
                {pdf ? (
                  <PDFViewer height={1000} width={1200}>
                    <ExpenditurePdf data={treeData} />
                  </PDFViewer>
                ) : (
                  <MDBox pt={3}>
                    {/* {JSON.stringify({ getHead })} */}
                    <div
                      className="d-flex justify-content-between m-1"
                      style={{ marginRight: 10, marginLeft: 10 }}
                    >
                      <div className="col-md-2">
                        <Input
                          type="select"
                          name="budget_year"
                          value={form.budget_year}
                          onChange={handleChange}
                        >
                          <option>--select--</option>
                          {years?.map((item) => (
                            <option key={item}>{item}</option>
                          ))}
                        </Input>
                      </div>
                      <div className="col-md-11">
                        <div className="text-center">
                          {/* <label>Select the report type</label> */}
                        </div>
                        <center>
                          <ButtonGroup>
                            {options?.map((item) => (
                              <Button
                                size="sm"
                                key={item.title}
                                outline={
                                  selectedOption.title !== item.title
                                    ? true
                                    : false
                                }
                                color={
                                  selectedOption.title === item.title
                                    ? "primary"
                                    : "secondary"
                                }
                                onClick={() =>
                                  handleButtonClick(
                                    item.value,
                                    item.title,
                                    item.code
                                  )
                                }
                              >
                                <small>{item.title}</small>
                              </Button>
                            ))}
                          </ButtonGroup>
                        </center>
                      </div>
                    </div>
                    <div className="p-3">
                      {/* {JSON.stringify(treeData)} */}
                      <Scrollbar>
                        {loading && (
                          <div className="loading-container text-center">
                            <Spinner
                              color="primary"
                              style={{ marginTop: "20px" }}
                            />{" "}
                            Loading...
                          </div>
                        )}
                        <Table
                          bordered
                          className="mt-3"
                          size="sm"
                          style={{ fontSize: 12 }}
                        >
                          <tbody>
                            {Object.keys(dataByMDA)?.map((group, grpIdx) => {
                              const prev_year2_actual_amount = dataByMDA[
                                group
                              ]?.reduce(
                                (a, b) =>
                                  a + ensureNumber(b.prev_year2_actual_amount),
                                0
                              );
                              const prev_year1_approve_amount = dataByMDA[
                                group
                              ]?.reduce(
                                (a, b) =>
                                  a + ensureNumber(b.prev_year1_approve_amount),
                                0
                              );
                              const prev_year1_actual_amount = dataByMDA[
                                group
                              ]?.reduce(
                                (a, b) =>
                                  a + ensureNumber(b.prev_year1_actual_amount),
                                0
                              );

                              const budget_amount = dataByMDA[group]?.reduce(
                                (a, b) => a + ensureNumber(b.budget_amount),
                                0
                              );
                              const total = dataByMDA[group]?.reduce(
                                (a, b) => a + ensureNumber(b.approve_amount),
                                0
                              );
                              const newFilterHead2 =
                                prev_year2_actual_amount === 0 &&
                                prev_year1_approve_amount === 0 &&
                                prev_year1_actual_amount === 0 &&
                                budget_amount === 0 &&
                                total === 0;

                              return (
                                <React.Fragment key={grpIdx}>
                                  {newFilterHead2 ? null : (
                                    <tr>
                                      <td
                                        style={{
                                          fontWeight: "bold",
                                          backgroundColor: "green",
                                          width: "10%",
                                          color: "white",
                                        }}
                                        colSpan={7}
                                      >
                                        {group}
                                        {" - "}
                                        {dataByMDA[group][0].mda_name}
                                      </td>
                                    </tr>
                                  )}
                                  {newFilterHead2 ? null : (
                                    <tr>
                                      <th style={{ ...bg, width: "10%" }}>
                                        Code
                                      </th>
                                      <th style={bg}>Description</th>
                                      <th style={bg}>{`${
                                        parseInt(form.budget_year) - 2
                                      } Full Year Actuals`}</th>
                                      <th style={bg}>{`${
                                        parseInt(form.budget_year) - 1
                                      } Aproved`}</th>
                                      <th style={bg}>{`${
                                        parseInt(form.budget_year) - 1
                                      } Performance Jan. to Aug.`}</th>
                                      <th style={bg}>{`${parseInt(
                                        form.budget_year
                                      )} MDA Proposal`}</th>
                                      <th style={bg}>{`${parseInt(
                                        form.budget_year
                                      )} Approved Budget`}</th>
                                    </tr>
                                  )}
                                  {generateTable2(dataByMDA[group])}
                                </React.Fragment>
                              );
                            })}
                          </tbody>
                        </Table>
                      </Scrollbar>
                    </div>
                  </MDBox>
                )}
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        <Footer />
      </DashboardLayout>
    </>
  );
}
